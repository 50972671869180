import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import { Toast } from "../services/Toast";

import {
  listProducts,
  deleteProduct,
  createProduct,
} from '../actions/productActions'
import { PRODUCT_CREATE_RESET } from '../constants/productConstants'
const apiUrl = process.env.REACT_APP_API_URL;
const ProductListScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1

  const dispatch = useDispatch()

  const productList = useSelector((state) => state.productList)
  const { loading, error, products, page, pages } = productList

  const productDelete = useSelector((state) => state.productDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = productDelete

  const productCreate = useSelector((state) => state.productCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    product: createdProduct,
  } = productCreate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const deleteHandler = (id) => {
    if (window.confirm("This can't be undone")) {
      dispatch(deleteProduct(id))
    }
  }

  const duplicateHandler = (id) => {
    axios({
      method: 'post',
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
      url: `${apiUrl}/api/products/duplicate/${id}`,
    }).then((res) => {
      if (res.status === 200) {
        Toast({ type: 'success', message: 'Product duplicated successfully!' });
        dispatch(listProducts('', pageNumber));
      }
    })
  }

  useEffect(() => {
    dispatch({ type: PRODUCT_CREATE_RESET })
    if (!userInfo || !userInfo.isAdmin) {
      history.push('/login')
    }
  }, [dispatch, history, userInfo, createdProduct, pageNumber])

  useEffect(() => {
    if (successDelete) {
      Toast({ type: 'success', message: 'Product deleted successfully!' });
      dispatch(listProducts('', pageNumber));
    }
  }, [successDelete])

  useEffect(() => {
    if (successCreate) {
      history.push(`/admin/product/${createdProduct.slug}/create`);
    } else {
      dispatch(listProducts('', pageNumber));
    }
  }, [successCreate])

  return (
    <>
      <section className='my-5'>
        <div className='container'>
          <Row className='align-items-center mb-4'>
            <Col>
              <h1 className='commonTitle--xl'>Products</h1>
            </Col>
            <Col className='text-end'>
              <a href='/admin/product/create'>
                <Button className='my-3 btnCommon'>
                  <span>Create Product</span>
                  <i className='fas fa-plus ms-3'></i>
                </Button>
              </a>
            </Col>
          </Row>
          {loadingDelete && <Loader />}
          {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
          {loadingCreate && <Loader />}
          {errorCreate && <Message variant='danger'>{errorCreate}</Message>}
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <>
              <Table striped bordered hover responsive className='table-sm productList__table'>
                <thead>
                  <tr>
                    <th>S.N</th>
                    <th>NAME</th>
                    <th>STATUS</th>
                    <th>QTY</th>
                    <th>PRICE</th>
                    <th>BRAND</th>
                    <th>Group ID</th>
                    <th>Duplicate / Edit / Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((product, i) => (
                    <tr key={product._id}>
                      <td className='text-center'>{i + 1}</td>
                      <td>
                        {product.name}
                      </td>
                      <td>
                        {product.isActive == true ? 'Active' : 'Draft'}
                      </td>
                      <td>{product.quantity}</td>
                      <td>₹{product.price}</td>
                      <td>{product.brand}</td>
                      <td>{product.group_id}</td>
                      <td className='edit-btns'>
                        <Button
                          style={{ marginLeft: '12px' }}
                          variant='light'
                          className='btn-sm action__Btn'
                          onClick={() => duplicateHandler(product._id)}
                        >
                          <i className='fas fa-clone'></i>
                        </Button>

                        <LinkContainer to={`/admin/product/${product._id}/edit`}>
                          <Button variant='light' className='btn-sm action__Btn'>
                            <i className='fas fa-edit'></i>
                          </Button>
                        </LinkContainer>

                        <Button
                          variant='danger'
                          className='btn-sm'
                          onClick={() => deleteHandler(product._id)}
                        >
                          <i className='fas fa-trash'></i>
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Paginate pages={pages} page={page} isAdmin={true} />
            </>
          )}
        </div>
      </section>
    </>
  )
}

export default ProductListScreen
