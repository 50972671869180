import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  listCategories,
  deleteCategory,
} from '../../../actions/categoryActions'

const CategoryAdmin = ({ history }) => {
  const dispatch = useDispatch()
  const categoryList = useSelector((state) => state.categoryList)
  const {
    loading: categoriesLoading,
    error: categoriesError,
    categories,
  } = categoryList

  const deleteACategory = useSelector((state) => state.deleteACategory)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = deleteACategory

  function deleteCategoryHandler(pId) {
    if (window.confirm('Are you sure')) {
      dispatch(deleteCategory(pId))
    }
  }

  useEffect(() => {
    dispatch(listCategories())
  }, [successDelete])

  return (
    <>
      {loadingDelete && 'Deleting...'}

      {categoriesLoading ? (
        'Fetching Categories...'
      ) : (
        <section>
          <div className='container'>
            <Row className='align-items-center my-5'>
              <Col>
                <h1 className='commonTitle--xl'>Categories</h1>
              </Col>
              <Col className='text-end'>
                <a href='/admin/category/create'>
                  <Button className='my-3 btnCommon'>
                    <i className='fas fa-plus'></i> Create a Category
                  </Button>
                </a>
              </Col>
            </Row>
            <>
              <Table striped bordered hover responsive className='table-sm'>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Products</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {categories?.map((category) => (
                    <LinkContainer
                    className='cursor-pointer'
                    to={`/admin/category/edit/${category.slug}`}
                  >
                    <tr key={category._id}>
                      <td>{category.name}</td>
                      <td>{category.name}</td>
                      <td>
                        <Button
                          variant='danger'
                          className='btn-sm'
                          onClick={() => deleteCategoryHandler(category._id)}
                        >
                          <i className='fas fa-trash'></i>
                        </Button>
                      </td>
                    </tr>
                    </LinkContainer>
                  ))}
                </tbody>
              </Table>
            </>
          </div>
        </section>
      )}
    </>
  )
}

export default CategoryAdmin
