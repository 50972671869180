import React, { useEffect, useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { listAllCollections } from '../../../actions/collectionAction';

const CollectionAdminScreen = () => {
  const [editCollectionId, setEditCollectionId] = useState(null);
  const [displayOrders, setDisplayOrders] = useState({});
  const [isActiveStatus, setIsActiveStatus] = useState({});
  
  const dispatch = useDispatch();
  const allCollections = useSelector(state => state.allCollections.collection);

  useEffect(() => {
    dispatch(listAllCollections());
  }, [dispatch]);

  useEffect(() => {
    if (allCollections && allCollections.collections) {
      const initialOrders = {};
      const initialIsActive = {};
      allCollections.collections.forEach(collection => {
        initialOrders[collection._id] = collection.displayOrder || 0;
        initialIsActive[collection._id] = collection.isActive;
      });
      setDisplayOrders(initialOrders);
      setIsActiveStatus(initialIsActive);
    }
  }, [allCollections]);

  const deleteHandler = async (id) => {
    if (!window.confirm('Are you sure you want to delete this collection?')) {
      return;  // Early return if user cancels the confirmation
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/collection/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          // Include authorization header if your API requires authentication
          // 'Authorization': `Bearer ${token}`
        }
      });
  
      if (!response.ok) {
        // If the DELETE operation failed, throw an error with the response status
        const errorData = await response.json(); // Assuming the server sends JSON response on error
        throw new Error(errorData.message || 'Failed to delete the collection');
      }
  
      // Optionally, refresh the list of collections from the server or update local state
      dispatch(listAllCollections());  // If using Redux to manage state
      alert('Collection successfully deleted');
    } catch (error) {
      // Display an error message if something goes wrong during the fetch operation
      alert(error.message || 'An error occurred while deleting the collection');
    }
  };
  

  const orderChange = (id, newOrder) => {
    setDisplayOrders(prev => ({
      ...prev,
      [id]: newOrder
    }));
    setEditCollectionId(id); // Mark as editing
  };

  const handleIsActiveChange = (id, status) => {
    setIsActiveStatus(prev => ({
      ...prev,
      [id]: status
    }));
    setEditCollectionId(id); // Mark as editing
  };

  const saveChanges = async (id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/collection/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          displayOrder: displayOrders[id],
          isActive: isActiveStatus[id],
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update collection');
      } else {
        dispatch(listAllCollections());
      }
    } catch (error) {
      alert(error.message);
    } finally {
      setEditCollectionId(null); // Reset editing state
    }
  };

  return (
    <section className='minHeight100'>
      <div className='container'>
        <Row className='align-items-center my-5'>
          <Col>
            <h1 className='commonTitle--xl'>Collections</h1>
            <span className='info_txt'>(Displayed in Home Page)</span>
          </Col>
          <Col className='text-end'>
            <LinkContainer to='/admin/collection/create'>
              <Button className='my-3 btnCommon'>
                <i className='fas fa-plus'></i> Create Collection
              </Button>
            </LinkContainer>
          </Col>
        </Row>
        <Table striped bordered hover responsive className='table-sm'>
          <thead>
            <tr>
              <th>NAME</th>
              <th>Edit/Delete</th>
            </tr>
          </thead>
          <tbody>
            {allCollections?.collections.map((collection) => (
              <tr key={collection._id}>
                <td>{collection.name}</td>
                <td className='collection-screen position-relative'>
                  <LinkContainer to={`/admin/collection/${collection._id}/edit`}>
                    <Button variant='light' className='btn-sm'>
                      <i className='fas fa-edit'></i>
                    </Button>
                  </LinkContainer>
                  <Button variant='danger' className='btn-sm' onClick={() => deleteHandler(collection._id)}>
                    <i className='fas fa-trash'></i>
                  </Button>
                  <input
                    className='form-control'
                    type='number'
                    value={displayOrders[collection._id] || 0}
                    onChange={(e) => orderChange(collection._id, parseInt(e.target.value, 10))}
                  />
                  <select
                    className='form-select'
                    value={isActiveStatus[collection._id]}
                    onChange={(e) => handleIsActiveChange(collection._id, e.target.value === 'true')}
                  >
                    <option value='true'>Active</option>
                    <option value='false'>Disabled</option>
                  </select>
                  {editCollectionId === collection._id && (
                    <Button onClick={() => saveChanges(collection._id)} className='changes'>
                      Save Changes
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </section>
  );
}

export default CollectionAdminScreen;
