import React, { useState, useEffect, useCallback } from 'react';
import { showAlert } from '../../../utils/showAlert';
import { Toast } from "../../../services/Toast";

const apiUrl = process.env.REACT_APP_API_URL;

const Category = ({ productId }) => {
  const [searchedCategories, setSearchedCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [newProductAdded, setNewProductAdded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const handleResponse = async (response, successMessage, errorMessage) => {
    if (!response.ok) {
      const errorData = await response.json();
      Toast({ type: 'error', message: errorData?.message || errorMessage });
      return false;
    } else {
      if (successMessage) Toast({ type: 'success', message: successMessage });
      return true;
    }
  };

  const debouncedSearch = useCallback(
    (() => {
      let timer;
      return (callback, delay) => {
        clearTimeout(timer);
        timer = setTimeout(callback, delay);
      };
    })(),
    []
  );

  const searchHandler = (e) => {
    const { value } = e.target;
    setSearchTerm(value);

    if (value.trim().length < 3) {
      setSearchedCategories([]); // Clear results for insufficient input
      return;
    }

    debouncedSearch(async () => {
      setSearchLoading(true);
      try {
        const response = await fetch(`${apiUrl}/api/category/name/${value.trim()}`);
        const data = await response.json();
        setSearchedCategories(data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        setSearchLoading(false);
      }
    }, 300); // 300ms debounce delay
  };

  const addCategory = async (categoryId) => {
    try {
      setLoading(true);
      const response = await fetch(`${apiUrl}/api/category/products/store/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ productId, categoryId }),
      });

      if (await handleResponse(response, 'Category added successfully!', 'Failed to add product to category')) {
        setNewProductAdded(true);
      }
    } catch (error) {
      Toast({ type: 'error', message: error.message });
    } finally {
      setLoading(false);
    }
  };

  const deleteCategory = async (categoryId) => {
    try {
      setLoading(true);
      const response = await fetch(`${apiUrl}/api/category/products/store/`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ productId, categoryId }),
      });

      if (await handleResponse(response, 'Category removed successfully!', 'Failed to remove product from category')) {
        setNewProductAdded(true);
        showAlert('Removed Category', 'danger');
      }
    } catch (error) {
      Toast({ type: 'error', message: error.message });
    } finally {
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${apiUrl}/api/products/categories/find/${productId}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });

      if (await handleResponse(response, null, 'Failed to fetch categories')) {
        const data = await response.json();
        setCategories(data?.categories || []);
      }
    } catch (error) {
      Toast({ type: 'error', message: error.message });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (productId) fetchCategories();
  }, [productId, newProductAdded]);

  return (
    <div>
      <div className="mb-3">
        <label htmlFor="tags" className="form-label">Add Categories</label>
        <input
          type="search"
          autoComplete="off"
          name="searchHandler"
          id="searchHandler"
          className="form-control"
          placeholder="Search Category (min 3 chars)"
          value={searchTerm}
          onChange={searchHandler}
        />
        {searchLoading && <p>Loading categories...</p>}
        <ul className="searchCat w-100">
          {searchedCategories.length > 0 ? (
            searchedCategories.map((item) => (
              <li key={item._id} onClick={() => addCategory(item._id)}>{item.name}</li>
            ))
          ) : (
            !searchLoading && searchTerm.trim().length >= 3 && <li>No categories found</li>
          )}
        </ul>
      </div>

      <div className="col-12 mt-5">
        <label htmlFor="tags" className="form-label">Selected Categories</label>
        {loading ? (
          <p>Loading...</p>
        ) : categories.length > 0 ? (
          <ul className="ul">
            {categories.map((item) => (
              <li key={item._id} className="form-control mb-2 d-flex align-items-center justify-content-between">
                <label>{item.name}</label>
                <button
                  className="btnCommon m-0"
                  disabled={loading}
                  onClick={() => deleteCategory(item._id)}
                >
                  Delete
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <p>No categories</p>
        )}
      </div>
    </div>
  );
};

export default Category;
