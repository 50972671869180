import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listOrders } from '../actions/orderActions'

const OrderListScreen = ({ history }) => {
  const dispatch = useDispatch()

  const orderList = useSelector((state) => state.orderList)
  const { loading, error, orders } = orderList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listOrders())
    } else {
      history.push('/login')
    }
  }, [dispatch, history, userInfo])

  return (
    <div className='container minHeight100'>
      <h1 className='commonTitle--xl my-5'>Orders</h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Table striped bordered hover responsive className='table-sm mb-5'>
          <thead>
            <tr>
              <th>ID</th>
              <th>Date</th>
              <th>Customer</th>
              <th>TOTAL</th>
              <th>PaymentMethod</th>
              <th>PAID</th>
              <th>DELIVERED</th>
            </tr>
          </thead>
          <tbody>
            {console.log(orders ,"orders")
            }
            {orders.map((order, i) => (
              <LinkContainer className='cursor-pointer' key={i} to={`/order/${order._id}`}>
                <tr key={order?._id}>
                  <td>{order?._id}</td>
                  <td>
                    {new Date(order.createdAt).toLocaleString('en-GB', {
                      day: 'numeric',
                      month: 'short',
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true,
                    }).replace(',', ' at')}
                  </td>
                  <td>{order.user && order.user.name}</td>
                  <td>₹{order.totalPrice}</td>
                  <td>{order?.paymentMethod}</td>
                  <td>{order?.isPaid ? "Paid" : "Not Paid"}</td>
                  <td>{order?.isDelivered ? "Delivered" : "Not Delivered"}</td>
                </tr>
              </LinkContainer>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  )
}

export default OrderListScreen
