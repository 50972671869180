import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';


const NewFeatures = ({ setAllFeatures, theFeatures, name }) => {

  const location = useLocation();

  // Check if the URL contains 'edit' or 'create'
  const isEditMode = location.pathname.includes('edit');

  console.log(isEditMode, "isEditMode");


  const [features, setFeatures] = useState([])
  const [newFeature, setNewFeature] = useState({
    heading: '',
    description: '',
  })
  const [updateData, setUpdateData] = useState('')

  // add feature
  const addFeature = () => {
    if (newFeature) {
      let num = Date.now() + Math.random()
      let newEntry = {
        id: num,
        heading: newFeature.heading,
        description: newFeature.description,
      }
      setFeatures([...features, newEntry])
      // SENDING BACK TO STORING DATA
      setAllFeatures([...features, newEntry])

      setNewFeature('')
    }
  }

  // get update feature
  const updateFeatureHandler = (e) => {
    setUpdateData({ ...updateData, [e.target.name]: e.target.value })
  }

  // update the feature
  const updateTheFeature = (e) => {
    let filterRecords = [...features].filter(
      (item) => item.id !== updateData.id
    )
    let updatedObject = [...filterRecords, updateData]
    setFeatures(updatedObject)
    // SENDING BACK TO STORING DATA
    setAllFeatures(updatedObject)
    setUpdateData('')
  }

  // cancel update
  const cancelFeature = (id) => {
    setUpdateData('')
  }

  // delete feature
  const deleteFeature = (id) => {
    let newFeatures = features.filter((feature) => feature.id !== id)
    setFeatures(newFeatures)
    // SENDING BACK TO STORING DATA
    setAllFeatures(newFeatures)
  }

  const createNewFeatureHandler = (e) => {
    setNewFeature({ ...newFeature, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    // setAllFeatures(features)
    setFeatures(theFeatures)
    // SENDING BACK TO STORING DATA
    setAllFeatures(theFeatures)
  }, [theFeatures])

  return (
    <section className='pdpFeature__section'>
      {isEditMode && updateData != "" &&
        <div className='card update_card'>
          <div className='card-header'>
            <label className='form-label'>Update feature</label>
          </div>
          <div className='card-body'>
            <input
              type='text'
              className='form-control mb-2'
              name='heading'
              placeholder='Feature Heading'
              value={updateData && updateData.heading}
              onChange={(e) => updateFeatureHandler(e)}
            />
            <input
              type='text'
              className='form-control mb-2'
              name='description'
              placeholder='Feature Description'
              value={updateData && updateData.description}
              onChange={(e) => updateFeatureHandler(e)}
            />
            <button
              type='button'
              onClick={updateTheFeature}
              className='btn btn-success mt-2 me-2'
            >
              Update Feature
            </button>
            <button
              type='button'
              onClick={cancelFeature}
              className='btn btn-danger mt-2'
            >
              Cancel Update
            </button>
          </div>
        </div>
      }

      <div className='card mt-3 create_card'>
        <div className='card-header'>
          <label className='form-label'>Create new feature</label>
        </div>
        <div className='card-body'>
          <input
            type='text'
            className='form-control mb-2'
            name='heading'
            placeholder='Feature Heading'
            onChange={(e) => createNewFeatureHandler(e)}
          />
          <input
            type='text'
            className='form-control mb-2'
            name='description'
            placeholder='Feature Description'
            onChange={(e) => createNewFeatureHandler(e)}
          />
          <button
            type='button'
            onClick={addFeature}
            className='btn btn-success mt-2'
          >
            Add Feature
          </button>
        </div>
      </div>

      {features && features.length ? '' : 'No features added'}

      <div className='card mt-3'>
        <div className='card-header'>
          <div className='form-label'>List of features</div>
        </div>
        <div className='card-body card-body-list'>
          {features
            ?.sort((a, b) => (a.id > b.id ? 1 : -1))
            ?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <div className='card-content featureCard__content'>
                    <div className='card-heading'><b className='me-3'>Heading:</b>{item?.heading}</div>
                    <div className='card-desc'><b className='me-3'>Description</b>{item?.description}</div>
                    <div className='cardBtn__box'>
                      <span
                        className='btn btn-primary'
                        onClick={() => deleteFeature(item.id)}
                      >
                        Delete
                      </span>
                      <span
                        className='btn btn-success'
                        onClick={() =>
                          setUpdateData({
                            id: item.id,
                            heading: item.heading,
                            description: item.description,
                          })
                        }
                      >
                        Update
                      </span>
                    </div>
                  </div>
                </React.Fragment>
              )
            })}
        </div>
      </div>
    </section >
  )
}

export default NewFeatures
