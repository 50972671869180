import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { Row, Col, ListGroup, Image, Card, Button, Container, Badge } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import {
  getOrderDetails,
  payOrder,
  deliverOrder,
} from '../../actions/orderActions'
import {
  ORDER_PAY_RESET,
  ORDER_DELIVER_RESET,
} from '../../constants/orderConstants'
import styles from './OrderScreen.module.scss'

const apiUrl = process.env.REACT_APP_API_URL;
const OrderScreen = ({ match, history }) => {
  const orderId = match.params.id

  const [sdkReady, setSdkReady] = useState(false)

  const dispatch = useDispatch()

  const orderDetails = useSelector((state) => state.orderDetails)
  const { order, loading, error } = orderDetails

  const orderPay = useSelector((state) => state.orderPay)
  const { loading: loadingPay, success: successPay } = orderPay

  const orderDeliver = useSelector((state) => state.orderDeliver)
  const { loading: loadingDeliver, success: successDeliver } = orderDeliver

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  if (!loading) {
    //   Calculate prices
    const addDecimals = (num) => {
      return (Math.round(num * 100) / 100).toFixed(2)
    }

    order.itemsPrice = addDecimals(
      order.orderItems.reduce((acc, item) => acc + item.price * item.qty, 0)
    )
  }

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    }

    const addPayPalScript = async () => {
      const { data: clientId } = await axios.get(`${apiUrl}/api/config/paypal`)
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}`
      script.async = true
      script.onload = () => {
        setSdkReady(true)
      }
      document.body.appendChild(script)
    }

    if (!order || successPay || successDeliver || order._id !== orderId) {
      dispatch({ type: ORDER_PAY_RESET })
      dispatch({ type: ORDER_DELIVER_RESET })
      dispatch(getOrderDetails(orderId))
    } else if (!order.isPaid) {
      if (!window.paypal) {
        addPayPalScript()
      } else {
        setSdkReady(true)
      }
    }
  }, [dispatch, orderId, successPay, successDeliver, order])

  const successPaymentHandler = (paymentResult) => {
    dispatch(payOrder(orderId, paymentResult))
  }

  const deliverHandler = () => {
    dispatch(deliverOrder(order))
  }

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant='danger'>{error}</Message>
  ) : (
    <>
      <Container className='my-3'>
        <Row className=''>
          <Col lg={8} className='mb-3'>
            <div className='d-flex align-items-center p-0 mt-3 mb-1'>
              <h2 className={styles.OrderId + " mb-0 p-0"}>Order #{order._id}</h2>
              <p className={styles.Badge + " " + styles.Paid + " m-0 me-2"}>{order.isPaid ? 'Paid' : 'Not paid'}</p>
              <p className={styles.Badge + " " + styles.Paid + " m-0"}>{order.isDelivered ? 'Delivered' : 'Not Delivered'}</p>
            </div>
            <p className={styles.Date}>
              {new Date(order.createdAt).toLocaleString('en-GB', {
                day: 'numeric',
                month: 'short',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
              }).replace(',', ' at')}
            </p>
          </Col>
          <Col md={8}>
            <Card className='mb-3'>
              <h4 className='commonTitle--wul p-3'>Order Items</h4>
              {order.orderItems.length === 0 ? (
                <Message>Order is empty</Message>
              ) : (
                <ListGroup variant='flush'>
                  {order.orderItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row className='align-items-center p-3'>
                        <Col md={1}>
                          <Image
                            src={`/${item.image}`}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col>
                          <Link to={`/product/${item.product}`}>
                            {item.name}
                          </Link>
                        </Col>
                        <Col md={4}>
                          {item.qty} x ₹{item.price} = ₹{item.qty * item.price}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}

            </Card>

            <Card>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  <h2>Order Summary</h2>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Items</Col>
                    <Col>₹{order.itemsPrice}</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Shipping</Col>
                    <Col>₹{order.shippingPrice}</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Tax</Col>
                    <Col>₹{order.taxPrice}</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Total</Col>
                    <Col>₹{order.totalPrice}</Col>
                  </Row>
                </ListGroup.Item>

                {userInfo &&
                  userInfo.isAdmin &&
                  order.isPaid &&
                  !order.isDelivered && (
                    <ListGroup.Item>
                      <Button
                        type='button'
                        className='btnCommon my-2 p-3'
                        onClick={deliverHandler}
                      >
                        Mark As Delivered
                      </Button>
                    </ListGroup.Item>
                  )}
              </ListGroup>
            </Card>

            <Card className='mt-3 mb-5'>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  <h4 className='commonTitle--wul'>Payment Method</h4>
                </ListGroup.Item>
                <ListGroup.Item>
                  <p className='mb-0'><strong>Method: </strong>{order.paymentMethod}
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>

          <Col md={4}>
            <Card className={styles.CustomerCard}>

              <div className='mb-2'>
                <p className='mb-1'><strong>Customer</strong></p>
                <p className='mb-0'>{order.user.name}</p>
              </div>

              <div className='mb-2'>
                <p className='mb-1'><strong>Contact Info</strong></p>
                <p className='mb-0'>{order.user.phone}</p>
                <p className='mb-0'>{order.user.email}</p>
              </div>

              <div className='mb-2'>
                <p className='mb-1'><strong>Shipping Address</strong></p>
                <p className='mb-0'>
                  {order.shippingAddress.address}, {order.shippingAddress.city}{' '}
                  {order.shippingAddress.postalCode},{' '}
                  {order.shippingAddress.country}
                </p>
              </div>

              <div className='mb-2'>
                <p className='mb-1'><strong>Billing Address</strong></p>
                <p className='mb-0'>
                  {order.shippingAddress.address}, {order.shippingAddress.city}{' '}
                  {order.shippingAddress.postalCode},{' '}
                  {order.shippingAddress.country}
                </p>
              </div>

            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default OrderScreen
