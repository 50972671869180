import React from 'react'

const Tax = ({ handleTaxChange, tax }) => {
  const handleChange = (event) => {
    handleTaxChange(event);
  }

  return (
    <>
      <div className='card my-5'>
        <div className='card-header'>
          <label className='form-label'>Tax</label>
        </div>
        <div className='card-body'>
          <div className='mb-3'>
            <label htmlFor='taxTitle' className='form-label'>
              Tax title
            </label>
            <input
              type='text'
              className='form-control'
              id='taxTitle'
              placeholder=''
              name='taxTitle'
              defaultValue={tax?.taxTitle}
              onChange={handleChange}
            />
          </div>

          <div className='mb-3'>
            <label htmlFor='taxRate' className='form-label'>
              Tax Rate
            </label>
            <input
              type='number'
              className='form-control'
              id='taxRate'
              placeholder=''
              name='taxRate'
              defaultValue={tax?.taxRate}
              onChange={handleChange}
            />
          </div>

        </div>
      </div>
    </>
  )
}

export default Tax
