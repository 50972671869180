import React from 'react'

const Pricing = ({ setPrice, price, setWasPrice, wasPrice }) => {
  return (
    <div className='card mb-3'>
      <div className='card-header'><label className='form-label'>Pricing</label></div>
      <div className='card-body'>
        <label htmlFor='productPrice' className='form-label'>
          Price
        </label>
        <div className='input-group mb-3'>
          <span className='input-group-text'>₹</span>
          <input
            onChange={(e) => setPrice(e.target.value)}
            id='productPrice'
            type='number'
            name='price'
            className='form-control'
            placeholder='0.00'
            defaultValue={price}
          />
        </div>
        <label htmlFor='productWasPrice' className='form-label'>
          Was Price
        </label>
        <div className='input-group mb-3'>
          <span className='input-group-text'>₹</span>
          <input
            onChange={(e) => setWasPrice(e.target.value)}
            id='productWasPrice'
            type='number'
            name='wasPrice'
            className='form-control'
            placeholder='0.00'
            defaultValue={wasPrice}
          />
        </div>
      </div>
    </div>
  )
}

export default Pricing
