import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Toast
export const Toast = ({ type = "success", message, time = 2000 }) => {
    const showToast = {
        success: toast.success,
        error: toast.error,
        info: toast.info,
        warning: toast.warning,
        default: toast,
    };

    const toastFn = showToast[type] || showToast.default;

    if (Array.isArray(message)) {
        message.forEach((item) => {
            toastFn(item.message, { autoClose: time });
        });
    } else {
        toastFn(message, { autoClose: time });
    }
};

// // Example Usage
// Toast({ type: "success", message: "Product saved successfully!" });
// Toast({ type: "error", message: "An error occurred!" });
