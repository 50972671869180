import React, { useState } from 'react';
import * as XLSX from 'xlsx';
 
function Highlights({ setHighlights, highlights }) {
  const [spec, setSpec] = useState([]);  // This state holds the specs extracted from Excel

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
  
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: 'buffer' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        const highlights = [];  // Temporarily holds the data from the Excel sheet
  
        for (let item of data) {
          if (item.Highlights) {  // Checks if Highlights exists in the row
            const highlight = item.Highlights.trim();  // Trims the string
            highlights.push(highlight);  // Pushes the highlight as a string into the array
          }
        }
        resolve(highlights);
      };
  
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  
    promise.then((d) => {
      setSpec(d);  // Updates the spec state with the extracted highlights
      setHighlights(d);  // Updates the parent component's highlights state
    });
  };
  

  return (
      <div className='card mt-3 mb-5'>
        <div className='card-header'>
          <label className='form-label'>Highlights</label>
        </div>

      <div className='card-body'>
        {highlights && highlights.length ? (
          <ul className='highlight__ul'>
            {highlights.map((item, index) => (
              <li key={index}>
                <span>{item}</span>
              </li>
            ))}
          </ul>
        ) : (
          <div className='mb-3'>
            <p>No Highlights added. Upload xlsx file.</p>
          </div>
        )}

        <input
          type='file'
          placeholder='Upload the file'
          className='custom-file-input'
          onChange={(e) => {
            const file = e.target.files[0];
            readExcel(file);
          }}
        />
      </div>
      </div>
  );
}

export default Highlights;
