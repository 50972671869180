import React, { useState, useEffect } from 'react'
import { Toast } from '../../../services/Toast';
import { useDispatch, useSelector } from 'react-redux'
import {
  getCategoryById,
  putCategoryById,
  listProductsInCategoryById,
} from '../../../actions/categoryActions'
const apiUrl = process.env.REACT_APP_API_URL;
const CategoryEditScreen = ({ match }) => {
  const pId = match.params.id
  const dispatch = useDispatch()

  const [searchedProducts, setSearchedProducts] = useState([])
  const [isSearching, setIsSearching] = useState(false);
  const [categoryName, setCategoryName] = useState('')
  const [slug, setSlug] = useState('')
  const [description, setDescription] = useState('')
  const [tags, setTags] = useState([])
  const [categoryId, setCategoryId] = useState('')

  const getCategory = useSelector((state) => state.getCategory)
  const { fetchCategory } = getCategory

  const putCategory = useSelector((state) => state.putCategory)
  const { category } = putCategory
  const [newProductAdded, setNewProductAdded] = useState(false)

  const listProductsInCategory = useSelector(
    (state) => state.listProductsInCategory
  )
  const {
    loading: listProductsLoading,
    error: listProductsError,
    products,
  } = listProductsInCategory


  function updateCategoryHandler() {
    const updatedCategory = {
      name: categoryName,
      slug: slug,
      description: description,
      tags: tags,
    }

    dispatch(putCategoryById(pId, updatedCategory))
  }

  function searchHandler(e) {
    const { value } = e.target
    fetchProductsByName(value)
    setIsSearching(true);
  }


  const fetchProductsByName = async (name) => {
    if (name.trim() === '') {
      setSearchedProducts([]); // Clear results when query is empty
      setIsSearching(false); // Reset searching state
      return;
    }


    try {
      const response = await fetch(`${apiUrl}/api/products/name/${name}`)
      const data = await response.json()
      setSearchedProducts(data)
    } catch (error) {
      console.error('Error fetching products:', error)
    }
  }

  async function addProducts(productId) {
    try {
      const response = await fetch(`${apiUrl}/api/category/products/store/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Set the content type to JSON
        },
        body: JSON.stringify({
          productId: productId,
          categoryId: categoryId,
        }),
      });

      if (!response.ok) {
        // Handle the error response
        const errorData = await response.json(); // Extract error message from JSON response
        throw new Error(errorData.message || 'Failed to add product to category');
      }
      else {
        setNewProductAdded(true)
      }

    } catch (error) {
      Toast({ type: 'errror', message: error?.message });
    }
  }

  async function deleteHandler(productId) {
    try {
      const response = await fetch(`${apiUrl}/api/category/products/store/`, {
        method: 'DELETe',
        headers: {
          'Content-Type': 'application/json', // Set the content type to JSON
        },
        body: JSON.stringify({
          productId: productId,
          categoryId: categoryId,
        }),
      });

      if (!response.ok) {
        // Handle the error response
        const errorData = await response.json(); // Extract error message from JSON response
        throw new Error(errorData.message || 'Failed to add product to category');
      }
      else {
        setNewProductAdded(true)
      }

    } catch (error) {
      Toast({ type: 'errror', message: error?.message });
    }
  }


  useEffect(() => {
    dispatch(getCategoryById(pId))
  }, [newProductAdded])


  useEffect(() => {
    if (fetchCategory) {
      setCategoryName(fetchCategory.name);
      setSlug(fetchCategory.slug);
      setDescription(fetchCategory.description);
      setTags(fetchCategory.tags);
      setCategoryId(fetchCategory._id)
    }
  }, [fetchCategory]);


  useEffect(() => {
    dispatch(listProductsInCategoryById(slug))
  }, [slug])

  useEffect(() => {
    dispatch(listProductsInCategoryById(slug))
  }, [newProductAdded])

  return (
    <>
      <section className='mt-5 mb-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 mb-5'>
              <h1 className='commonTitle--xl'>Edit {categoryName} Category </h1>
            </div>
            <div className='col-lg-12'>
              <div className='card mb-3'>
                <div className='card-body'>
                  <div className='mb-2'>
                    <label htmlFor='name' className='form-label'>
                      Name
                    </label>
                    <input
                      onChange={(e) => setCategoryName(e.target.value)}
                      value={categoryName}
                      type='text'
                      autoComplete='off'
                      name='name'
                      className='form-control'
                      id='name'
                      placeholder='Category Name'
                    />
                  </div>

                  <div className='mb-2'>
                    <label htmlFor='slug' className='form-label'>
                      Slug
                    </label>
                    <input
                      onChange={(e) => setSlug(e.target.value)}
                      value={slug}
                      type='text'
                      autoComplete='off'
                      name='slug'
                      className='form-control'
                      id='slug'
                      placeholder='Category Slug'
                    />
                  </div>

                  <div className='mb-2'>
                    <label htmlFor='description' className='form-label'>
                      Description
                    </label>
                    <input
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                      type='text'
                      autoComplete='off'
                      name='description'
                      className='form-control'
                      id='description'
                      placeholder='A Description'
                    />
                  </div>


                  <div className='mb-3'>
                    <label htmlFor='tags' className='form-label'>
                      Add Products
                    </label>
                    <input
                      onChange={(e) => searchHandler(e)}
                      type='search'
                      autoComplete='off'
                      name='searchHandler'
                      className='form-control'
                      id='searchHandler'
                      placeholder='Search Products'
                    />
                    <ul className={isSearching && 'searchCat w-100'}>
                      {isSearching &&
                        (searchedProducts.length > 0 ? (
                          searchedProducts.map((item) => (
                            <li key={item._id} onClick={() => addProducts(item._id)}>
                              {item.name}
                            </li>
                          ))
                        ) : (
                          <li className=''>No products found</li>
                        ))}
                    </ul>

                  </div>

                  <button
                    className='btn btn-success'
                    onClick={updateCategoryHandler}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >



      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-8'>
              {products?.products && products?.products.length > 0 ?
                <ul className='categoryMenu__list'>
                  {products?.products.map((item, index) => (
                    <li value={item._id} key={item._id}>
                      <span>{index + 1}. {item.name}</span>
                      <a className='btnCommon' onClick={() => deleteHandler(item._id)}>Delete</a>
                    </li>
                  ))}
                </ul>
                : <p>No products</p>}

            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default CategoryEditScreen
